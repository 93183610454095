import { APIFeaturesConfig, APIFeatureConfig, APIBundleConfig } from '@/APIModels/configuration/features/types';
import { UIRecipientConfig } from '@/UIModels/configuration/features/recipientConfig';
import { UIJourneyConfig } from './features/journeyConfig';

export enum DATA_BUNDLES {
  PatientNameBasic = 'name_basic',
  PatientNameMiddle = 'name_middle',
  PatientNamePreferred = 'name_preferred',
  PatientNamePronouns = 'name_pronouns',
  PatientDOBAge = 'dob',
  PatientGovernmentID = 'government_id',
  PatientRace = 'race',
  PatientEthnicity = 'ethnicity',
  PatientIndigenousIdentity = 'indigenous_identity',
  PatientSex = 'sex',
  PatientGender = 'gender',
  PatientEducation = 'education',
  PatientInsurance = 'insurance',
}

export class UIFeaturesConfig {
  public recipientConfig: UIRecipientConfig;
  public journeyConfig: UIJourneyConfig;

  public constructor(apiSource: APIFeaturesConfig) {
    this.recipientConfig = new UIRecipientConfig(apiSource.recipient);
    this.journeyConfig = new UIJourneyConfig(apiSource.recipient_journey);
  }

  public static empty(): UIFeaturesConfig {
    return {
      recipientConfig: {
        enabled: true,
        demographics: {
          enabled: true,
          registrationInfo: {
            enabled: true,
            urgentRecipient: new UIFeatureConfig(),
          },
          personalInfo: new UIFeatureConfig(),
          financialAssistance: new UIFeatureConfig(),
          bundles: new UIBundleConfig(),
        },
        contactInfo: {
          enabled: false,
          contactDetails: new UIFeatureConfig(),
          addresses: new UIFeatureConfig(),
        },
        recipientDocuments: new UIFeatureConfig,
        recipientGeneralClinical: {
          enabled: false,
          bloodType: new UIFeatureConfig(),
          measurements: new UIFeatureConfig(),
        },
        recipientSerology: new UIFeatureConfig(),
        recipientDeath: new UIFeatureConfig(),
      },
      journeyConfig: {
        enabled: true,
        journeyOverview: {
          enabled: true,
          journeyDetails: {
            enabled: true,
            urgentListing: new UIFeatureConfig(),
            referredWith: new UIFeatureConfig(),
          },
          phaseStatus: {
            enabled: true,
            workflowStep: new UIFeatureConfig(),
          },
        },
        referralDetails: new UIFeatureConfig(),
        clinicalNotes: new UIFeatureConfig(),
        checklists: new UIFeatureConfig(),
        donorAcceptability: new UIFeatureConfig(),
        manageWaitlist: {
          enabled: false,
          activeListingTransfer: new UIFeatureConfig(),
        },
        transplantDetails: new UIFeatureConfig(),
        postTransplantFollowUp: {
          enabled: false
        },

        liverSpecifics: {
          enabled: false,
          peldResults: new UIFeatureConfig(),
          hccCriteria: new UIFeatureConfig(),
          hccResults: new UIFeatureConfig(),
          bridgingTherapy: new UIFeatureConfig(),
          downstagingTherapy: new UIFeatureConfig(),
          aldProgramParticipation: new UIFeatureConfig(),
          exceptionDiseases: new UIFeatureConfig(),
        },
        heartSpecifics: {
          enabled: false,
          heartMeasurements: new UIFeatureConfig(),
          vadProcedures: new UIFeatureConfig(),
          mechanicalCirculatorySupport: new UIFeatureConfig(),
          hemodynamicMeasurements: new UIFeatureConfig(),
          thoracicMeasurements: new UIFeatureConfig(),
        },
        kidneySpecifics: {
          enabled: false,
          paediatricAgeOverride: new UIFeatureConfig(),
        },
        lungSpecifics: {
          enabled: false,
          thoracicMeasurements: new UIFeatureConfig(),
        },
      },
    };
  }
}

export class UIFeatureConfig {
  public enabled = false;

  public constructor(apiSource?: APIFeatureConfig) {
    if (apiSource) this.enabled = apiSource.enabled;
  }
}

export class UIBundleConfig {
  public defined: string[] = [];
  public enabled: string[] = [];

  public constructor(apiSource?: APIBundleConfig) {
    let uiKey: string;
    for(const [key, value] of Object.entries(apiSource || {})) {
      uiKey = key.replace('_enabled', '');
      this.defined.push(uiKey);
      if (value) this.enabled.push(uiKey);
    }
  }

  // Check if one bundle is defined the feature's bundle config (true or false)
  public isDefined(uiKey: string): boolean {
    return this.defined.includes(uiKey);
  }

  // Check if one bundle is enabled (true)
  public isEnabled(uiKey: string): boolean {
    return this.enabled.includes(uiKey);
  }

  // Check if all of the specified bundles are enabled
  public allEnabled(uiKeys: string[]): boolean {
    const filtered = uiKeys.filter((uiKey: string) => { return this.isEnabled(uiKey); });
    return filtered.length === uiKeys.length;
  }

  // Check if at least one of the specified bundles are enabled
  public anyEnabled(uiKeys: string[]): boolean {
    const filtered = uiKeys.filter((uiKey: string) => { return this.isEnabled(uiKey); });
    return filtered.length > 0;
  }
}
