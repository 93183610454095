<template>
  <validation-observer ref="validations" as="div" class="task-body">
    <form-layout
      :disabled="!enableForm"
      :form-id="`checklist-${editState.id}-form`"
    >
      <template v-slot:title>
        <!-- Mode indicator / subsection form title (H5) -->
        <legend>
          <h5 class="legend-title">
            {{ editState.isNew ? $t("new_checklist_item") : $t("selected_checklist_item") }}
          </h5>
        </legend>
      </template>
      <template v-slot:contents>
        <div class="row">
          <div class="standard-form-group">
            <text-input 
              :input-id="`checklist_${editState.id}.name`"
              :name='$t("item_name")'
              :label='$t("item_name")'
              :ruleKey="`checklist_${editState.id}.name`"
              v-model="editState.name"
              >
            </text-input>
          </div>
          <div class="standard-form-group">
            <checkbox-input
              :input-id="`checklist_${editState.id}.external`"
              :name='$t("external")'
              :labelName='$t("external")'
              :ruleKey="`checklist_${editState.id}.external`"
              v-model="editState.external"
              label="Yes"
              >
            </checkbox-input>
          </div>
          <div class="standard-form-group">
            <select-input
              :select-id="`checklist_${editState.id}.status`"
              :ruleKey="`checklist_${editState.id}.status`"
              :name='$t("status")'
              :label='$t("status")'
              :options="recipientJourneyChecklistItemStatus"
              v-model="editState.status"
            />
          </div>
        </div>
      </template>
      <template v-slot:save>
        <save-toolbar
          :show="showSaveToolbar"
          ref="saveTask"
          class="card-footer action-row temp-saving row"
          :label="$t('save')"
          :cancelButton="true"
          @save="handleSave"
          @cancel="handleCancel"
          :labelSaveAndCreateAnother='$t("save_and_create_another")'
          :showSaveAndCreateAnother="showSaveAndCreateAnother"
          @saveAndCreateAnother="handleSaveAndCreateAnother"
        />
      </template>
    </form-layout>
  </validation-observer>
</template>

<script lang="ts">
import { State } from 'vuex-facing-decorator';
import { mixins } from "vue-facing-decorator";
import { GenericCodeValue } from '@/store/types';
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import FormLayout from '@/components/shared/FormLayout.vue';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import { Component, Watch, Prop } from 'vue-facing-decorator';
import SelectInput from '@/components/shared/SelectInput.vue';
import TextInput from '@/components/shared/TextInput.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import { SaveResult, APIPermittedActions } from '@/types';
import { useCurrentPageStore } from '@/stores/currentPage';
import { RulesQuery } from '@/types';
import { UIChecklistTask } from "@/UIModels/checklist";
import { UIRecipient } from '@/UIModels/recipient';
import { UIJourney } from '@/UIModels/journey';
import { EP } from '@/api-endpoints';
import { i18nMessages } from '@/i18n';
import { parseFormErrors } from '@/utils';
import { IdLookup } from '@/store/validations/types';

@Component({
  components: {
    FormLayout,
    SaveToolbar,
    SelectInput,
    CheckboxInput,
    TextInput,
  },
  ...i18nMessages([
    require('@/components/organs/shared/_locales/ChecklistSection.json'),
    require('./_locales/common.json'),
  ]),
  emits: [
    'success',
    'successNewCreateAnother',
    'cancel',
  ],
})
export default class ChecklistTaskForm extends mixins(DateUtilsMixin) {
  @State(state => state.lookups.recipient_journey_checklist_item_status) recipientJourneyChecklistItemStatus!: GenericCodeValue[];

  // UI view model prop, treated as immutable by this component
  // NOTE: please do NOT use this for v-models in template, it belongs to the parent!
  @Prop({ required: true }) selection!: UIChecklistTask;

  @Prop({ default: false }) showNew!: boolean;
  @Prop({ default: null }) checklistId!: string;
  @Prop({ default: null }) groupId!: string;
  @Prop({ default: false }) disabled!: boolean;

  private editState: UIChecklistTask = UIChecklistTask.buildNew();

  private permittedActions: APIPermittedActions[] = [];

  private get showSaveAndCreateAnother(): boolean {
    return this.editState && this.editState.isNew ? true : false;
  }

  // Return default state value for new tasks
  public get defaultStatus(): GenericCodeValue|null {
    return (this.recipientJourneyChecklistItemStatus || []).find((status: GenericCodeValue) => { return status.default; }) || null;
  }

  @Watch('selection', { immediate: true, deep: true })
  private resetEditState(): void {
    // if edit
    if (!this.showNew) {
      if (this.editState.active) { 
        this.editState = this.selection.copyViewModel();
        this.editState.active = true;
      } else {
        this.editState = this.selection.copyViewModel();
      }
    // else new
    } else {
      this.editState = UIChecklistTask.buildNew();
      this.editState.checklist_id = this.checklistId;
      this.editState.group_id = this.groupId;
      this.editState.status = this.defaultStatus && this.defaultStatus.code ? this.defaultStatus.code : null;
      const params = {};
      this.queryRules(params);
    }
  }

  @Watch('showNew', { immediate: true, deep: true })
  private updateValidationRules(): void {
    const params = {};
    this.queryRules(params);
  }

  get canSave(): boolean {
    return this.editState.permitted_actions.includes('update');
  }

  // Which Recipient view model are we viewing on the current page?
  // NOTE: this is shared client state from the pinia store
  get currentRecipient(): UIRecipient {
    const currentPageStore = useCurrentPageStore();
    return currentPageStore.currentRecipient as UIRecipient;
  }

  // Which Recipient view model are we viewing on the current page?
  // NOTE: this is shared client state from the pinia store
  get currentJourney(): UIJourney {
    const currentPageStore = useCurrentPageStore();
    return currentPageStore.currentJourney as UIJourney;
  }

  // Process save button click event
  private async handleSave(): Promise<void> {
    if (this.saveToolbar) this.saveToolbar.startSaving();
    const saveParams = { 
      selected: this.editState,
      checklistId: this.checklistId,
      recipient: this.currentRecipient,
      journey: this.currentJourney
    };
    try {
      const success: SaveResult = await this.editState.save(saveParams);
      this.handleSuccess(success);
    } catch (error: unknown) {
      this.handleErrors(error as SaveResult);
    }
  }

  // Process successful save result
  private handleSuccess(success: SaveResult): void {
    if (this.saveToolbar) this.saveToolbar.stopSaving(success);
    // prepare package for parent
    const apiTask = success.responseData ? success.responseData : null;
    const uiTask = UIChecklistTask.buildFromAPIChecklistTask(apiTask);

    // send back apiTask and current editState (uiTask) to update group's task or add a new task to the relevant group
    this.$emit('success', apiTask, uiTask);
  }

  // Process save button click event
  private async handleSaveAndCreateAnother(): Promise<void> {
    if (this.saveToolbar) this.saveToolbar.startSaving();
    const saveParams = { 
      selected: this.editState,
      checklistId: this.checklistId,
      recipient: this.currentRecipient,
      journey: this.currentJourney
    };
    try {
      const success: SaveResult = await this.editState.save(saveParams);
      this.handleSuccessCreateAnother(success);
    } catch (error: unknown) {
      this.handleErrors(error as SaveResult);
    }
  }

  // Process successful save result
  private handleSuccessCreateAnother(success: SaveResult): void {
    if (this.saveToolbar) this.saveToolbar.stopSaving(success);
    // prepare package for parent
    const apiTask = success.responseData ? success.responseData : null;
    const uiTask = UIChecklistTask.buildFromAPIChecklistTask(apiTask);

    // send back apiTask and current editState (uiTask) to update group's task or add a new task to the relevant group
    this.$emit('successNewCreateAnother', apiTask, uiTask);
  }

  // Handle validations for this form component
  private handleErrors(errors: any) {
    // Derive errors for UI input fields based on API error results
    const formErrors: any = parseFormErrors(errors, this.idLookup());

    // inject api errors into vee-validate
    const validationObserver = this.$refs.validations as any;
    if (validationObserver) validationObserver.setErrors(formErrors);

    const saveToolbar = this.$refs.saveTask as SaveToolbar;
    if (saveToolbar) saveToolbar.stopSaving(errors);
  }

  // Reset edit state based on selected address for cancel button click event
  private handleCancel(): void {
    this.resetEditState();
    this.resetSaveToolbar();
    this.$emit('cancel', this.editState);
  }

  // Reference to Patient Address toolbar
  get saveToolbar(): SaveToolbar|null {
    const saveToolbar = this.$refs.saveTask;
    if (!saveToolbar) return null;

    return saveToolbar as SaveToolbar;
  }

  /**
   * Resets Form Errors
  */
  public resetFormErrors(): void {
    // Refer to the validations object
    const validations = this.$refs.validations as any;
    // Reset the form errors
    if (validations) validations.resetForm();
  }

  // Dismiss save toolbar success or error indicator
  public resetSaveToolbar(): void {
    if (this.saveToolbar) this.saveToolbar.reset();
  }

  // Can we enable the form?
  get enableForm(): boolean {
    return this.permittedActionsAllowCreateOrUpdate && !this.disabled;
  }

  // Can we show the save toolbar?
  get showSaveToolbar(): boolean {
    return this.permittedActionsAllowCreateOrUpdate && !this.disabled;
  }

  // Can we enable the save toolbar?
  get enableSaveToolbar(): boolean {
    return this.permittedActionsAllowCreateOrUpdate && !this.disabled;
  }

  // Check permitted actions list
  get permittedActionsAllowCreateOrUpdate(): boolean {
    // First we check special case, on #new endpoint permitted_actions is an empty array
    if (this.permittedActions.length === 0) return true;

    // We have a list of permitted actions, so now we can check for "update" keyword
    return this.permittedActions.includes(APIPermittedActions.Update);
  }

  // Load validation rules with specified query parameters
  // NOTE: this can involve either #new or #edit rules, depending on the situation
  private queryRules(query: RulesQuery): void {
    if (this.editState.isNew) {
      this.queryChecklistTaskNewRules(query);
    } else {
      this.queryChecklistTextEditRules(query);
    }
  }

  // Load #edit rules with specified query parameters
  private async queryChecklistTextEditRules(query: RulesQuery): Promise<void> {
    await this.$store.dispatch('validations/loadValidationsWithActions', {
      route: EP.recipients.journeys.checklists.tasks.edit,
      payload: [
        [':recipient_id', this.currentRecipient.clientId], 
        [':journey_id', this.currentJourney.journeyId], 
        [':checklist_id', this.editState.checklist_id], 
        [':id', this.editState.id]
      ],
      prefix: `checklist_${this.editState.id}`,
      query,
    });
  }

  // Load #new rules with specified query parameters
  private async queryChecklistTaskNewRules(query: RulesQuery): Promise<void> {
    await this.$store.dispatch('validations/loadValidationsWithActions', {
      route: EP.recipients.journeys.checklists.tasks.new,
      payload: [
        [':recipient_id', this.currentRecipient.clientId], 
        [':journey_id', this.currentJourney.journeyId], 
        [':checklist_id', this.editState.checklist_id], 
        [':id', this.editState.id]
      ],
      prefix: `checklist_${this.editState.id}`,
      query,
    });
  }

  public showFakeSuccess() {
    if (this.saveToolbar) this.saveToolbar.showFakeSuccess();
  }

  // Key mapping for field-level validation errors
  public idLookup(): IdLookup {
    const results: { [key: string]: string } = {};

    results[`checklist_${this.editState.id}.name`]     = `checklist_${this.editState.id}.name`;
    results[`checklist_${this.editState.id}.external`] = `checklist_${this.editState.id}.external`;
    results[`checklist_${this.editState.id}.status`]   = `checklist_${this.editState.id}.status`;

    return results;
  }
}
</script>
