import { TransplantFactors } from '@/store/recipientJourney/types';
import { ObjectId } from '@/store/types';

export interface APIPostTransplantFollowUpInterface {
  _id?: ObjectId;
  entry_date?: string|null;
  follow_up_type?: string|null;
  years_since_transplant?: string|null;
  graft_status_code?: string|null;
  follow_up_date?: string|null;
  graft_rejection_date?: string|null;
  graft_failure_date?: string|null;
  graft_failure_cause_code?: string|null;
  donor_specific_antibody?: string|null;
  recipient_status_code?: string|null;
  lost_to_follow_up_date?: string|null;
  lost_to_follow_up_reason?: string|null;
}

export interface APIPancreasWholeSpecificDetails {
  organ_specific_details?: {
    vessels_required?: boolean|null;
    general_comments?: string|null;
  }
}

export interface APITransplantDetailsInterface {
  _id?: ObjectId;

  admission_datetime?: string|null,
  organ_source?: string|null,
  specific_organ_transplanted?: string|null,
  odo_donor_id?: string|null,
  odo?: string|null,
  donor_cross_clamp_datetime?: string|null,
  cold_preservation_start_datetime?: string|null,
  cold_preservation_end_datetime?: string|null,
  cold_ischemia_seconds?: number|null,
  perfusion_device_status?: string|null,
  perfusion_device_used?: string|null,
  reperfusion_datetime?: string|null,
  transplant_start_datetime?: string|null,
  transplant_end_datetime?: string|null,
  anastomosis_datetime?: string|null,
  transplant_complete?: boolean|null,
  not_transplanted_reason?: string|null,
  surgical_complications?: boolean|null,
  clavien_dindo_classification?: string|null,
  intra_operative_death?: boolean|null,
  discharge_date?: string|null,
  follow_up_provider?: string|null,

  // transplant attributes model properties loaded from database
  donor_id?: ObjectId|null;
  donor_client_id?: number;
  factors?: TransplantFactors;
  // read-only properties generated dynamically by web app API
  deceased_donor_id?: string;
  living_donor_id?: string;
  allocated_donor_id?: string;
  allocated_donor_client_id?: string;
  transplant_details_applicable?: boolean;
  // flags UI can send to API in transplant update request params
  copy_to_cluster?: boolean;
}  

export interface APIDonorAcceptabilityDetails {
  accept_criteria_conditions?: APIDonorAcceptabilityCondition[];

  accept_donor_types?: string[]|null;
  applicable_donor_types?: { [key: string]: any }|null;

  min_age_yrs?: number|null;
  min_height_cm?: number|null;
  min_weight_kg?: number|null;

  max_age_yrs?: number|null;
  max_height_cm?: number|null;
  max_weight_kg?: number|null;

  comments?: string|null;
}

export interface APIDonorAcceptabilityCondition {
  _id?: ObjectId;
  acceptable?: boolean|null;
  donor_condition?: string|null;
}

export enum PostTransplantFollowUpValues {
  Annual = 'annual',
  Living = 'living',
  LossOfContact = 'loss_of_contact',
  Rejection = 'rejection',
  Failure = 'failure'
}
