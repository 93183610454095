import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "legend-title" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "standard-form-group" }
const _hoisted_4 = { class: "standard-form-group" }
const _hoisted_5 = { class: "standard-form-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_input = _resolveComponent("text-input")!
  const _component_checkbox_input = _resolveComponent("checkbox-input")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_save_toolbar = _resolveComponent("save-toolbar")!
  const _component_form_layout = _resolveComponent("form-layout")!
  const _component_validation_observer = _resolveComponent("validation-observer")!

  return (_openBlock(), _createBlock(_component_validation_observer, {
    ref: "validations",
    as: "div",
    class: "task-body"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_form_layout, {
        disabled: !_ctx.enableForm,
        "form-id": `checklist-${_ctx.editState.id}-form`
      }, {
        title: _withCtx(() => [
          _createElementVNode("legend", null, [
            _createElementVNode("h5", _hoisted_1, _toDisplayString(_ctx.editState.isNew ? _ctx.$t("new_checklist_item") : _ctx.$t("selected_checklist_item")), 1)
          ])
        ]),
        contents: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_text_input, {
                "input-id": `checklist_${_ctx.editState.id}.name`,
                name: _ctx.$t("item_name"),
                label: _ctx.$t("item_name"),
                ruleKey: `checklist_${_ctx.editState.id}.name`,
                modelValue: _ctx.editState.name,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.name) = $event))
              }, null, 8, ["input-id", "name", "label", "ruleKey", "modelValue"])
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_checkbox_input, {
                "input-id": `checklist_${_ctx.editState.id}.external`,
                name: _ctx.$t("external"),
                labelName: _ctx.$t("external"),
                ruleKey: `checklist_${_ctx.editState.id}.external`,
                modelValue: _ctx.editState.external,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editState.external) = $event)),
                label: "Yes"
              }, null, 8, ["input-id", "name", "labelName", "ruleKey", "modelValue"])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_select_input, {
                "select-id": `checklist_${_ctx.editState.id}.status`,
                ruleKey: `checklist_${_ctx.editState.id}.status`,
                name: _ctx.$t("status"),
                label: _ctx.$t("status"),
                options: _ctx.recipientJourneyChecklistItemStatus,
                modelValue: _ctx.editState.status,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editState.status) = $event))
              }, null, 8, ["select-id", "ruleKey", "name", "label", "options", "modelValue"])
            ])
          ])
        ]),
        save: _withCtx(() => [
          _createVNode(_component_save_toolbar, {
            show: _ctx.showSaveToolbar,
            ref: "saveTask",
            class: "card-footer action-row temp-saving row",
            label: _ctx.$t('save'),
            cancelButton: true,
            onSave: _ctx.handleSave,
            onCancel: _ctx.handleCancel,
            labelSaveAndCreateAnother: _ctx.$t("save_and_create_another"),
            showSaveAndCreateAnother: _ctx.showSaveAndCreateAnother,
            onSaveAndCreateAnother: _ctx.handleSaveAndCreateAnother
          }, null, 8, ["show", "label", "onSave", "onCancel", "labelSaveAndCreateAnother", "showSaveAndCreateAnother", "onSaveAndCreateAnother"])
        ]),
        _: 1
      }, 8, ["disabled", "form-id"])
    ]),
    _: 1
  }, 512))
}