import { APIUserPreferences, APIWidgetPreferences } from '@/APIModels/user/preferences/types';
import { DEFAULT_DASHBOARD_PREFERENCES, UIDashboard } from '@/UIModels/dashboard';
import { APIUser } from '@/APIModels/user/types';
import { SaveResult } from '@/types';
import { APIRoute, EP } from '@/api-endpoints';
import { UIError } from '@/UIModels/error';
import axios from 'axios';
import { DEFAULT_WIDGET_PREFERENCES, UIBaseWidget } from '@/UIModels/dashboard/baseWidget';

export const DEFAULT_PREFERENCE_DATA: APIUserPreferences = {
  dashboard: DEFAULT_DASHBOARD_PREFERENCES,
  columns: {},
};

export class UIPreferences {
  public preferenceData: APIUserPreferences = {
    dashboard: {
      widgets: {},
    },
    columns: {},
  };

  public constructor(apiUser?: APIUser) {
    if (apiUser) this.updateFromAPIUser(apiUser);
  }

  public updateFromAPIUser(apiUser: APIUser) {
    this.updateFromAPIPreferenceData(apiUser.preferences.data);
  }

  public updateFromAPIPreferenceData(apiPreferenceData: any) {
    let sanitized = DEFAULT_PREFERENCE_DATA;
    if (!!apiPreferenceData && apiPreferenceData instanceof Object) sanitized = apiPreferenceData;
    if (!sanitized.dashboard) sanitized.dashboard = DEFAULT_DASHBOARD_PREFERENCES;
    this.preferenceData = sanitized;
  }

  public async saveCustomizeDashboard(uiDashboard: UIDashboard): Promise<SaveResult> {
    const ep = APIRoute(EP.users.preferences);
    const newPreferenceData = Object.assign({}, this.preferenceData);
    uiDashboard.widgets.forEach((uiWidget: UIBaseWidget) => {
      if (!newPreferenceData.dashboard.widgets[uiWidget.name]) newPreferenceData.dashboard.widgets[uiWidget.name] = DEFAULT_WIDGET_PREFERENCES;
      newPreferenceData.dashboard.widgets[uiWidget.name] = uiWidget.extractWidgetOptionsPatch();
    });
    const payload = { data: newPreferenceData };
    try {
      const response = await axios.put(ep, payload);
      const preferenceData = response.data.preferences.data as APIUserPreferences;
      this.preferenceData = preferenceData;
      return { success: true, responseData: preferenceData.dashboard };
    } catch (error: unknown) {
      console.warn(error);
      throw new UIError('preferences', error);
    }
  }

  public async saveWidgetOptions(uiWidget: UIBaseWidget): Promise<SaveResult> {
    const ep = APIRoute(EP.users.preferences);
    const newPreferenceData = Object.assign({}, this.preferenceData);
    if (!newPreferenceData.dashboard.widgets[uiWidget.name]) newPreferenceData.dashboard.widgets[uiWidget.name] = DEFAULT_WIDGET_PREFERENCES;
    newPreferenceData.dashboard.widgets[uiWidget.name] = uiWidget.extractWidgetOptionsPatch();
    const payload = { data: newPreferenceData };
    try {
      const response = await axios.put(ep, payload);
      const preferenceData = response.data.preferences.data as APIUserPreferences;
      this.preferenceData = preferenceData;
      return { success: true, responseData: preferenceData.dashboard.widgets[uiWidget.name] };
    } catch (error: unknown) {
      console.warn(error);
      throw new UIError('preferences', error);
    }
  }

  public async saveWidgetCustom(uiWidget: UIBaseWidget, apiWidgetPreferences: APIWidgetPreferences): Promise<SaveResult> {
    const ep = APIRoute(EP.users.preferences);
    const newPreferenceData = Object.assign({}, this.preferenceData);
    newPreferenceData.dashboard.widgets[uiWidget.name] = apiWidgetPreferences;
    const payload = { data: newPreferenceData };
    try {
      const response = await axios.put(ep, payload);
      const preferenceData = response.data.preferences.data as APIUserPreferences;
      this.preferenceData = preferenceData;
      return { success: true, responseData: preferenceData.dashboard.widgets[uiWidget.name] };
    } catch (error: unknown) {
      console.warn(error);
      throw new UIError('preferences', error);
    }
  }

  public async saveColumns(columnKey: string, selectedColumns: string[]): Promise<SaveResult> {
    const ep = APIRoute(EP.users.preferences);
    const newPreferenceData = Object.assign({}, this.preferenceData);
    newPreferenceData.columns[columnKey] = selectedColumns;
    const payload = { data: newPreferenceData };
    try {
      const response = await axios.put(ep, payload);
      const preferenceData = response.data.preferences.data as APIUserPreferences;
      this.preferenceData = preferenceData;
      return { success: true, responseData: preferenceData.columns };
    } catch (error: unknown) {
      console.warn(error);
      throw new UIError('preferences', error);
    }
  }
}
