import { APIPermittedActions, APIRules, SaveResult } from '@/types';
import vuexStore from '@/store'; // for gradual conversion, see fullUserDetails
import { ValidationRuleSet } from '@/APIModels/validations/types';
import { useCurrentPageStore } from '@/stores/currentPage';
import { APIBookmarkEntry, APIWidgetBookmarkCustomPreferences } from '@/APIModels/user/preferences/types';
import { UIError } from '@/UIModels/error';
import { v4 as uuidv4 } from 'uuid';
import { DEFAULT_WIDGET_BOOKMARKS_CUSTOM_PREFERENCES, UIWidgetBookmarks } from '@/UIModels/dashboard/widgetBookmarks';

const BOOKMARK_VALIDATION_RULES: ValidationRuleSet = {
  name: {
    validation_rules: 'required|max_length:250',
  },
  link: {
    validation_rules: 'required|url|max_length:2000',
  },
};

export class UIBookmark {
  public uiWidget: UIWidgetBookmarks;
  public apiSource?: APIBookmarkEntry;
  public id: string;
  public name = '';
  public link = '';
  public createdAt = '';
  public updatedAt = '';

  get isNew(): boolean {
    return !this.apiSource;
  }

  get permittedActions(): APIPermittedActions[] {
    if (this.isNew) return [APIPermittedActions.Create];
    return [APIPermittedActions.Update, APIPermittedActions.Destroy];
  }

  // Define new UI view model structure
  public constructor(uiWidget: UIWidgetBookmarks, apiBookmarkEntry?: APIBookmarkEntry) {
    this.uiWidget = uiWidget;
    this.id = uuidv4();

    if (apiBookmarkEntry) this.updateFromAPI(apiBookmarkEntry);
  }

  // Commit field-level validation rules
  private setRules(rules: APIRules): void {
    vuexStore.commit('validations/resetPrefix', 'bookmarks');
    vuexStore.commit('validations/set', { rules: { ['bookmarks']: rules } });
  }

  public updateFromAPI(apiBookmarkEntry: APIBookmarkEntry): void {
    this.apiSource = apiBookmarkEntry;
    this.id = apiBookmarkEntry.id;
    this.name = apiBookmarkEntry.name;
    this.link = apiBookmarkEntry.link;
    this.createdAt = apiBookmarkEntry.created_at;
    this.updatedAt = apiBookmarkEntry.updated_at;
  }

  // Load resource data and permitted actions
  public async load(opts: { id: string }): Promise<void> {
    this.setRules(BOOKMARK_VALIDATION_RULES);
    if (!opts?.id) return;

    const apiWidgetBookmarkCustomPreferences = (useCurrentPageStore().preferences.preferenceData.dashboard.widgets.bookmarks.custom || DEFAULT_WIDGET_BOOKMARKS_CUSTOM_PREFERENCES) as APIWidgetBookmarkCustomPreferences;
    const apiBookmarkEntry = (apiWidgetBookmarkCustomPreferences.entries || []).find((apiBookmarkEntry: APIBookmarkEntry) => { return apiBookmarkEntry.id === opts.id; });
    if (!apiBookmarkEntry) throw new UIError('bookmark');
    this.updateFromAPI(apiBookmarkEntry);
  }

  public async save(opts: { id: string }): Promise<SaveResult> {
    if (!opts) throw new UIError('bookmark');

    // Sanitize bookmark data
    const newBookmarkEntry: APIBookmarkEntry = {
      id: this.id,
      name: this.name,
      link: this.link,
      created_at: this.createdAt || new Date().toISOString(),
      updated_at: new Date().toISOString(),
    };
    const apiEntries = this.uiWidget.customPreferences.entries;
    if (opts.id) {
      // Update existing bookmark
      const index = apiEntries.findIndex((apiEntry: APIBookmarkEntry) => { return apiEntry.id === opts.id; } );
      if (index < 0) throw new UIError('bookmark');
      apiEntries[index] = newBookmarkEntry;
    } else {
      // Create new bookmark
      apiEntries.push(newBookmarkEntry);
    }
    return await this.uiWidget.saveBookmarkEntries(apiEntries);
  }

  public async delete(opts: { id: string }): Promise<SaveResult> {
    if (!opts || !opts.id) throw new UIError('bookmark');

    // Delete bookmark
    const apiEntries = this.uiWidget.customPreferences.entries;
    const index = apiEntries.findIndex((apiEntry: APIBookmarkEntry) => { return apiEntry.id === opts.id; } );
    if (index < 0) throw new UIError('bookmark');
    apiEntries.splice(index, 1); 
    return await this.uiWidget.saveBookmarkEntries(apiEntries);
  }
}